import { handleErrorWithSentry } from "@sentry/sveltekit";
import type { ClientInit, HandleClientError } from "@sveltejs/kit";

import { env } from "$env/dynamic/public";
import { initSentryInBrowser } from "$lib/sentry/browser";

export const init: ClientInit = async () => {
  globalThis.CDN_BASE = env.PUBLIC_CDN_BASE;

  initSentryInBrowser();
};

export const handleError: HandleClientError = handleErrorWithSentry();
