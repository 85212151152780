import { captureConsoleIntegration } from "@sentry/browser";
import * as Sentry from "@sentry/sveltekit";

import { env } from "$env/dynamic/public";
import { PermissionError, Redirect } from "$lib/fetch/parseResponse";

export function initSentryInBrowser() {
  const release = env.PUBLIC_GIT_SHA;

  Sentry.init({
    dsn: env.PUBLIC_SENTRY_SVELTEKIT_CLIENT_DSN,
    integrations: [
      captureConsoleIntegration({
        levels: ["warn", "error", "assert"],
      }),
    ],
    release,
    environment: env.PUBLIC_ENV_NAME,
    attachStacktrace: true,
    ignoreErrors: [
      "TypeError: Failed to fetch dynamically imported module",
      "Load failed",
      "TypeError: Load failed",
      "TypeError: Failed to fetch",
      "Error: Failed to load",
      "Failed to fetch",
      "TypeError: NetworkError when attempting to fetch resource",
      "TypeError: Importing a module script failed",
      "TypeError: error loading dynamically imported module",
      "Failed to load Remote Plugin Error: Failed to load https://cdn.segment.com",
      "Sprig (Actions) Error: Failed to load",
      "NotSupportedError: The user agent does not support public key credentials.", // Seems to be coming from Sprig
      "ResizeObserver loop completed with undelivered notifications",
      "Datadog Browser SDK: Datadog Session Replay failed to start: a timeout occurred while initializing the Worker",
      "localStorage may be unavailable", // Segment "Unable to access ajs_anonymous_id, localStorage may be unavailable" error
      "localStorage may be full", // Segment "Unable to access ajs_user_id, localStorage may be full"
      "could not ensure focus",
      "Failed to create PluginFactory",
      "/* Blocked by AdGuard */",
      "SecurityError: The operation is insecure.",
    ],
    beforeSend: function (event, hint) {
      // Filter out UnhandledRejection errors that have no information
      // From https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-828834651
      if (event?.exception?.values && event.exception.values.length == 1) {
        const e = event.exception.values[0];
        if (
          e.type === "UnhandledRejection" &&
          e.value?.startsWith("Non-Error promise rejection captured with value:")
        ) {
          return null;
        }
      }

      const error = hint.originalException;
      if (error instanceof Redirect || error instanceof PermissionError) {
        return null;
      }

      return event;
    },
  });
  Sentry.setTags({
    server_name: env.PUBLIC_DATADOG_HOST_NAME,
    version: release,
    service: "agent-webapp",
  });
}
